@charset "utf-8";



@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

ol.bibliography {	
  padding-inline-start: 0px;	
}	

ol.bibliography li{	
list-style: none;	
}	

.bibliography ul.nav {	
margin-left: 0px;	
margin-bottom: 10px;	
padding-inline-start: 0px;	
}	

.bibliography li.pill {	
display: inline-block;	
margin-right: .5em;	
}	

.layout--splash .page__hero--overlay .page__title{	
color: #000;	
text-shadow: none;	
}	

.layout--splash .page__hero--overlay .page__lead{	
color: #000;	
text-shadow: none;	
}	

.page__hero--overlay a{	
color: #393e46	
}

.btn-links .btn {
  padding: 5px .5rem;
  line-height:1
}

.btn--info {
  background-color: transparent;
  border: 1px solid transparent;
  margin-top: .25rem!important;
  margin-right: .25rem!important;
  margin-bottom: .25rem!important;
  color: #0092ca;
  border-color:#0092ca;
}

.btn--info:visited{
  background-color: #0092ca;
  color: #0092ca;
}

// .feature__item--inlinecenter{
//   text-align: center;
//   font-size: 1em;
// }